export const environment = {
	env: 'dev',
	apiUrl:'https://hbr-hm-api-gateway-service-prod.105ecu06v16o.eu-de.codeengine.appdomain.cloud/observations/api/v1/',
	loginUrl:'https://hbr-hm-api-gateway-service-prod.105ecu06v16o.eu-de.codeengine.appdomain.cloud/observations/api/v1/',
	apiKey:'04110abe-f4a0-43ca-8514-a2dcedb6d069',
							
	expectedEndPointIndicator:'7',
	astro_api:'https://hbr-hm-api-gateway-service-prod.105ecu06v16o.eu-de.codeengine.appdomain.cloud/astro/api/v1/',
							
	astro_api_clientId:'04110abe-f4a0-43ca-8514-a2dcedb6d069',
	osr_api:'https://hbr-hm-api-gateway-service-prod.105ecu06v16o.eu-de.codeengine.appdomain.cloud/osr/api/v1/',
	osr_api_clientId:'04110abe-f4a0-43ca-8514-a2dcedb6d069',
							
	mapPortalId:'2bb390428b83428cb53a46625cdd1684',
	localUrl:'',
	isLogin: false,
							
	apiAuthUrl: 'https://hbr-hm-auth-helper-service-prod.105ecu06v16o.eu-de.codeengine.appdomain.cloud/api/v1/auth/token',
              
	activeDirectoryClientId:'48a94979-9e2f-4a8b-9926-8fd18e12ed4b',
	activeDirectoryTenantId:'30453998-4784-4b0e-bdb0-a8ba14eff494',
							
	activeDirectoryRedirectUrl:'https://hydrometeo.naiade-dr.portofrotterdam.com/auth',
	activeDirectoryResource:'api://1bc57ad1-bd40-4033-ba84-cb83fdeeeb38',
              
	title:'Aeolus 2.5 DR', 
	rws_api:'https://hbr-hm-api-gateway-service-prod.105ecu06v16o.eu-de.codeengine.appdomain.cloud/rws/api/v1/',
	rws_api_clientId:'04110abe-f4a0-43ca-8514-a2dcedb6d069',
                                                        
	watsonUrl:'https://etr7l8.internetofthings.ibmcloud.com/dashboard/',
	naiadeMapUrl:'https://portmaps.maps.arcgis.com/apps/webappviewer/index.html?id=91c02cca7e1b4ee58735701415fe9aa4',
	alertsUrl:'https://ibmbmmgmt.mybluemix.net/index?subscriptionId=22ed5963-f4d2-4e88-901d-6f9af480c3ca&dashboard=ans.dashboard.alertviewer'
};
